import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';
import { makeStyles, SwipeableDrawer } from "@material-ui/core";

import { blue010 } from "../../../uiKit/assets/colors/colors";
import { Logo } from "../../../uiKit/assets/svg/logo";
import CustomButton from "../../../uiKit/Button/CustomButton";
import Title from "../../../uiKit/Title/Title";
import { Close, MenuIcon } from '../../../uiKit/assets/svg/icons';
import { useDispatch } from "react-redux";
import { setTutorials, setFAQ, setOurValues, setPrivacy, setTerms } from "../../../redux/state/commonSlice";

const useStyles = makeStyles(() => ({
    paper: {
        width: '100%',
        backgroundColor: blue010,
        maxHeight: '538px',
        height: '100%'
    }
}))

const MobileMenuLanding = ({
    scrollToHowItWorks,
    scrollToAbout,
    scrollToContact
}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const drawerClasses = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const signUpHandler = useCallback(() => {
        ReactGA.event('sign_up_mobile_menu');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(false));
        dispatch(setTutorials(false));
        setIsOpen(false);

        history.push('/sign-up')
    }, [history, dispatch]);

    const loginHandler = useCallback(() => {
        ReactGA.event('log_in_mobile_menu');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(false));
        dispatch(setTutorials(false));
        setIsOpen(false);

        history.push('/login')
    }, [history, dispatch]);

    const onFAQClick = useCallback(() => {
        ReactGA.event('faq_in_mobile_menu');
        setIsOpen(false);

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setOurValues(false));
        dispatch(setFAQ(true));
        window.scrollTo(0, 0);
    }, [dispatch])

    const onTutorialsClick = useCallback(() => {
        ReactGA.event('tutorials_in_mobile_menu');
        setIsOpen(false);

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(false));
        dispatch(setTutorials(true));
        window.scrollTo(0, 0);
    }, [dispatch])

    const onOurValuesClick = useCallback(() => {
        ReactGA.event('our_values_in_mobile_menu');
        setIsOpen(false);

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setOurValues(true));
        dispatch(setFAQ(false));
        dispatch(setTutorials(false));
        window.scrollTo(0, 0);
    }, [dispatch])

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                <MenuIcon />
            </div>
            <SwipeableDrawer
                anchor='right'
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                classes={drawerClasses}
            >
                <div className='sidebarMobile'>
                    <div
                        className='mobileMenuLanding'
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <div>
                                <Logo />
                            </div>
                            <div onClick={() => setIsOpen(false)} >
                                <Close />
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Title
                                text="How it works"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={() => {
                                    setIsOpen(false);
                                    scrollToHowItWorks();
                                }}
                            />
                            <Title
                                text="About"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={() => {
                                    setIsOpen(false);
                                    scrollToAbout();
                                }}
                            />
                            <Title
                                text="Contact"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={() => {
                                    setIsOpen(false);
                                    scrollToContact();
                                }}
                            />
                            <Title
                                text="Our Values"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={onOurValuesClick}
                            />
                            <Title
                                text="FAQ's"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={onFAQClick}
                            />
                            <Title
                                text="Tutorials"
                                style={{ padding: '18px 16px', borderRadius: '12px' }}
                                onClick={onTutorialsClick}
                            />

                            <div style={{ marginTop: '8px' }}>
                                <CustomButton
                                    title='Sign up'
                                    onClick={signUpHandler}
                                    style={{ marginBottom: '16px' }}
                                />
                                <CustomButton
                                    title='Log in'
                                    onClick={loginHandler}
                                    isWhite
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}

const LandingHeader = ({ referances }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const loginHandler = useCallback(() => {
        ReactGA.event('log_in_landing');
        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(false));
        history.push('/login')
    }, [history, dispatch]);

    const scrollToHowItWorks = useCallback(() => {

        const { signUp } = referances;

        ReactGA.event('how_it_works_header');

        if (!!signUp) {
            dispatch(setPrivacy(false));
            dispatch(setTerms(false));
            dispatch(setTutorials(false));
            dispatch(setFAQ(false));
            dispatch(setOurValues(false));
            setTimeout(() => {
                signUp.current.scrollIntoView();
            }, [200])
        } else {
            history.push('/')
        }
    }, [referances, history, dispatch])

    const scrollToAbout = useCallback(() => {

        const { about } = referances;

        ReactGA.event('about_header');

        if (!!about) {
            dispatch(setPrivacy(false));
            dispatch(setTerms(false));
            dispatch(setTutorials(false));
            dispatch(setFAQ(false));
            dispatch(setOurValues(false));
            setTimeout(() => {
                about.current.scrollIntoView();
            }, [200])
        } else {
            history.push('/')
        }
    }, [referances, history, dispatch])

    const scrollToContact = useCallback(() => {

        const { contact } = referances;

        ReactGA.event('contact_header');

        if (!!contact) {
            dispatch(setPrivacy(false));
            dispatch(setTerms(false));
            dispatch(setTutorials(false));
            dispatch(setFAQ(false));
            dispatch(setOurValues(false));
            setTimeout(() => {
                contact.current.scrollIntoView();
            }, [200])
        } else {
            history.push('/')
        }
    }, [referances, history, dispatch])

    const onTutorialsClick = useCallback(() => {

        ReactGA.event('tutorials_header');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setOurValues(false));
        dispatch(setFAQ(false));
        dispatch(setTutorials(true));
    }, [dispatch])

    const onFAQClick = useCallback(() => {
        ReactGA.event('faq_header');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setOurValues(false));
        dispatch(setTutorials(false));
        dispatch(setFAQ(true));
    }, [dispatch])

    const onOurValuesClick = useCallback(() => {
        ReactGA.event('our_values_header');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(true));
        window.scrollTo(0, 0);
    }, [dispatch])

    const onLogoClick = () => {
        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setOurValues(false));
        dispatch(setFAQ(false));
        history.push('/')
    }

    return (
        <div className='landingHeader' >
            <div
                style={{
                    width: '172px',
                    cursor: 'pointer'
                }}
                onClick={onLogoClick}
            >
                <Logo />
            </div>
            <div className='navigation'>
                <Title
                    text="How it works"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scrollToHowItWorks}
                />
                <Title
                    text="About"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scrollToAbout}
                />
                <Title
                    text="Contact"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scrollToContact}
                />
                <Title
                    text="Our Values"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={onOurValuesClick}
                />
                <Title
                    text="FAQ's"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={onFAQClick}
                />
                <Title
                    text="Tutorials"
                    className='hoverNav'
                    style={{
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={onTutorialsClick}
                />
            </div>
            <div className="navigation">
                <CustomButton
                    isWhite
                    title='Log in'
                    onClick={loginHandler}
                    style={{ width: '172px' }}
                />
            </div>
            <div className='mobileMenu'>
                <MobileMenuLanding
                    scrollToAbout={scrollToAbout}
                    scrollToContact={scrollToContact}
                    scrollToHowItWorks={scrollToHowItWorks}
                />
            </div>
        </div>
    )
}

export default LandingHeader;