import { createSlice } from "@reduxjs/toolkit"

const commonInitialState = {
    notFound: false,
    screenWidth: window.innerWidth,
    privacy: false,
    terms: false,
    tutorials: false,
    FAQ: false,
    ourValues: false,
    loaded: {
        account: false,
        community: false,
        admins: false,
        page: true,
    },
}

const commonSlice = createSlice({
    name: 'common',
    initialState: commonInitialState,
    reducers: {
        setNotFoundCommon(state, action) {
            state.notFound = action.payload;
        },
        setScreenWidth(state, action) {
            state.screenWidth = action.payload;
        },
        setPrivacy(state, action) {
            state.privacy = action.payload;
        },
        setTerms(state, action) {
            state.terms = action.payload;
        },
        setTutorials(state, action) {
            state.tutorials = action.payload;
        },
        setFAQ(state,action){
            state.FAQ = action.payload;
        },
        setOurValues(state, action) {
            state.ourValues = action.payload;
        },
        setLoaded(state, action) {
            const { type, value } = action.payload;

            state.loaded[type] = value;
        },
        clearLoaded(state) {
            state.loaded = commonInitialState.loaded
        },
        clearLoadedOnChangeCommunity(state) {
            state.loaded.admins = commonInitialState.loaded.admins;
            state.loaded.community = commonInitialState.loaded.community;
            state.loaded.page = commonInitialState.loaded.page;
        }
    }
})

export const {
    setNotFoundCommon,
    setScreenWidth,
    setPrivacy,
    setTerms,
    setTutorials,
    setFAQ,
    setOurValues,
    setLoaded,
    clearLoaded,
    clearLoadedOnChangeCommunity,
} = commonSlice.actions;
export default commonSlice.reducer;