import React, { useCallback, useEffect, useState } from 'react';
import './landingPage.scss';
import LandingHeader from './landingHeader';
import SignupSection from './signupSection';
import Groups from './groups';
import HowItWorks from './howItWorks';
import Advantages from './advantages';
import About from './about';
import AboutGradient from './aboutGradient';
import Team from './team';
import ContactUs from './contactUs';
import Footer from './footer';
import Terms from './Terms/terms';
import Policy from './Terms/policy';
import ScrollToTopIcon from '../../../components/ScrollToTopIcon/scrollToTopIcon';
import { useDispatch } from 'react-redux';
import { setScreenWidth } from '../../../redux/state/commonSlice';
import { shallowEqual, useSelector } from "react-redux";
import ReactGA from 'react-ga4';
import HowTo from './tutorials';
import FAQ from './faq';
import OurValues from './ourValues';

const LandingPage = () => {

    const dispatch = useDispatch();

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    const [referances, setReferances] = useState({});
    const privacyOpen = useSelector(state => state.common.privacy);
    const termsOpen = useSelector(state => state.common.terms);
    const tutorialsOpen = useSelector(state => state.common.tutorials);
    const FAQOpen = useSelector(state => state.common.FAQ);
    const ourValuesOpen = useSelector(state => state.common.ourValues);

    const updateReferance = useCallback((referance) => setReferances(referance), []);

    useEffect(() => {
        window.addEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)));
        return () => window.removeEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)))
    }, [screenWidth, dispatch])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/landing-page' })
    }, [])

    useEffect(() => {
        if (privacyOpen || termsOpen || tutorialsOpen) {
            window.scrollTo(0, 0);
        }
    }, [privacyOpen, termsOpen, tutorialsOpen])

    return (
        <div className='wrapper' >
            <div className='container'>
                <LandingHeader referances={referances} />
                <div style={!termsOpen && !privacyOpen && !tutorialsOpen && !ourValuesOpen && !FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <SignupSection />
                    <Groups />
                    <HowItWorks setRef={updateReferance} />
                    <Advantages setRef={updateReferance} />
                    <AboutGradient setRef={updateReferance} />
                    <About />
                    <Team />
                    <ContactUs setRef={updateReferance} />
                </div>
                <div style={termsOpen && !privacyOpen && !tutorialsOpen && !ourValuesOpen && !FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <Terms />
                </div>
                <div style={!termsOpen && privacyOpen && !tutorialsOpen && !ourValuesOpen && !FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <Policy />
                </div>
                <div style={!termsOpen && !privacyOpen && tutorialsOpen && !ourValuesOpen && !FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <HowTo />
                </div>
                <div style={!termsOpen && !privacyOpen && !tutorialsOpen && ourValuesOpen && !FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <OurValues />
                </div>
                <div style={!termsOpen && !privacyOpen && !tutorialsOpen && !ourValuesOpen && FAQOpen ? { display: 'block' } : { display: 'none' }}>
                    <FAQ />
                </div>

                <Footer referances={referances} />
            </div>
            <ScrollToTopIcon />
        </div>
    )
}

export default LandingPage;