import moment from "moment";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";

import { gray800 } from "../../../uiKit/assets/colors/colors";
import { Logo } from "../../../uiKit/assets/svg/logo";
import { Youtube } from "../../../uiKit/assets/svg/socialIcons";
import Title from "../../../uiKit/Title/Title";
import { setTutorials, setFAQ, setOurValues, setPrivacy, setTerms } from "../../../redux/state/commonSlice";
import { YOUTUBE_LINK } from "../../../helpers/constants";

const Footer = ({ referances }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const scrollToHowItWorks = useCallback(() => {

        const { signUp } = referances;

        ReactGA.event('how_it_works_footer');

        if (!!signUp) {
            dispatch(setPrivacy(false));
            dispatch(setTerms(false));
            dispatch(setTutorials(false));
            dispatch(setFAQ(false));
            dispatch(setOurValues(false));
            setTimeout(() => {
                signUp.current.scrollIntoView();
            }, [200])
        } else {
            history.push('/')
        }
    }, [referances, history, dispatch])

    const scrollToAbout = useCallback(() => {

        const { about } = referances;

        ReactGA.event('about_footer');

        if (!!about) {
            dispatch(setPrivacy(false));
            dispatch(setTerms(false));
            dispatch(setTutorials(false));
            dispatch(setFAQ(false));
            dispatch(setOurValues(false));
            setTimeout(() => {
                about.current.scrollIntoView();
            }, [200])
        } else {
            history.push('/')
        }
    }, [referances, history, dispatch])

    const scrollToContact = useCallback(() => {

        const { contact } = referances;

        ReactGA.event('contact_footer');

        if (!!contact) {
            dispatch(setPrivacy(false));
            dispatch(setFAQ(false));
            dispatch(setTerms(false));
            dispatch(setTutorials(false));
            dispatch(setOurValues(false));
            setTimeout(() => {
                contact.current.scrollIntoView();
            }, [200])
        } else {
            history.push('/')
        }
    }, [referances, history, dispatch])

    const onLogoClick = () => {
        dispatch(setPrivacy(false));
        dispatch(setFAQ(false));
        dispatch(setTerms(false));
        dispatch(setOurValues(false));
        dispatch(setTutorials(false));
        history.push('/')
    }

    const onPrivacyClick = useCallback(() => {
        ReactGA.event('privacy_policy');

        dispatch(setPrivacy(true));
        dispatch(setFAQ(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setOurValues(false));
        window.scrollTo(0, 0);
    }, [dispatch])

    const onTermsClick = useCallback(() => {
        ReactGA.event('terms_and_conditions');

        dispatch(setPrivacy(false));
        dispatch(setFAQ(false));
        dispatch(setTerms(true));
        dispatch(setOurValues(false));
        dispatch(setTutorials(false));
    }, [dispatch])

    const onTutorialsClick = useCallback(() => {
        ReactGA.event('tutorials_footer');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(false));
        dispatch(setTutorials(true));
    }, [dispatch])

    const onFAQClick = useCallback(() => {
        ReactGA.event('faq_footer');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setFAQ(true));
        dispatch(setOurValues(false));
        window.scrollTo(0, 0);
    }, [dispatch])

    const onOurValuesClick = useCallback(() => {
        ReactGA.event('our_values_footer');

        dispatch(setPrivacy(false));
        dispatch(setTerms(false));
        dispatch(setTutorials(false));
        dispatch(setFAQ(false));
        dispatch(setOurValues(true));
        window.scrollTo(0, 0);
    }, [dispatch])

    return (
        <div className="footer" >
            <div className='footerMenu' >
                <div onClick={onLogoClick} style={{ cursor: 'pointer' }}>
                    <Logo />
                </div>
                <div className="dividerFooterMobile" />
                <div className="footerNavigation" >
                    <Title
                        text="How it works"
                        fontWeight={500}
                        color={gray800}
                        className='hoverNav'
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={scrollToHowItWorks}
                    />
                    <Title
                        text="About"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={scrollToAbout}
                    />
                    <Title
                        text="Contact"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={scrollToContact}
                    />
                    <Title
                        text="Our Values"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            marginRight: '16px',
                            cursor: 'pointer'
                        }}
                        onClick={onOurValuesClick}
                    />
                    <Title
                        text="FAQ's"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={onFAQClick}
                    />
                    <Title
                        text="Tutorials"
                        className='hoverNav'
                        fontWeight={500}
                        color={gray800}
                        style={{
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                        onClick={onTutorialsClick}
                    />
                </div>
            </div>

            <div className="dividerFooter" />

            <div className="connections" >
                <Title
                    text={`© ${moment(Date.now()).format('YYYY')} OurCommunity`}
                    fontWeight={400}
                    lineHeight={24}
                />
                <Title
                    text="Privacy Policy"
                    fontWeight={400}
                    lineHeight={24}
                    onClick={onPrivacyClick}
                    style={{ cursor: 'pointer' }}
                    className='hoverNav'
                />
                <Title
                    text="Terms and Conditions"
                    fontWeight={400}
                    lineHeight={24}
                    onClick={onTermsClick}
                    style={{ cursor: 'pointer' }}
                    className='hoverNav'
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '160px' }}>
                    <a href={YOUTUBE_LINK} rel="noreferrer" target={"_blank"}>
                        <Youtube />
                    </a>
                </div>
            </div>

            <div className="connectionsMobile" >
                <div >
                    <Title
                        text="Privacy Policy"
                        fontWeight={400}
                        className='hoverNav'
                        lineHeight={24}
                        style={{ marginBottom: '19px', cursor: 'pointer' }}
                        onClick={onPrivacyClick}
                    />
                    <Title
                        text={`© ${moment(Date.now()).format('YYYY')} OurCommunity`}
                        fontWeight={400}
                        lineHeight={24}
                    />
                </div>
                <div>
                    <Title
                        text="Terms and Conditions"
                        fontWeight={400}
                        className='hoverNav'
                        lineHeight={24}
                        style={{ marginBottom: '19px', cursor: 'pointer' }}
                        onClick={onTermsClick}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                        <a href={YOUTUBE_LINK} rel="noreferrer" target={"_blank"}>
                            <Youtube />
                        </a>
                    </div>
                </div>
            </div>

            <div className="dividerFooter" />

            <Title
                text="Dedicated in memory of Chaim Meir Halevi Lieberman o.b.m."
                fontWeight={400}
                lineHeight={24}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '24px',
                }}
            />
        </div>
    )
}

export default Footer;